import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { BizziBotInfo, BizziBotWarning, BizziBotWarning2, TrashIcon } from "assets/images/svg/icons";
import {
  AlertNotification,
  AlertNotificationHandle,
  BackHeader,
  LoadingView,
  ToastManager,
  useBackHandler,
} from "components";
import useInteractionManager from "components/hooks/useInteractionManager";
import AttachmentType from "constants/AttachmentType";
import { COMPANIES } from "constants/Companies";
import { InvoiceType } from "constants/ExpenseApproval";
import { useAuth } from "contexts/AuthContext";
import { useDeleteExpense } from "hooks/expense/useDeleteExpense";
import { useExpDeleteExpenseAttachments } from "hooks/expense/useExpDeleteExpenseAttachments";
import { useExpExpenseDetailsQuery } from "hooks/expense/useExpExpenseDetailsQuery";
import { useExpInsertExpenseAttachments } from "hooks/expense/useExpInsertExpenseAttachments";
import { useExpUpdateExpenseMutation } from "hooks/expense/useExpUpdateExpenseMutation";
import { useExpInsertExpenseReportMutation } from "hooks/reports/useExpInsertExpenseReportMutation";
import { useExpSubmitExpenseReportMutation } from "hooks/reports/useExpSubmitExpenseReportMutation";
import useUploadFileSignedURL from "hooks/useUploadFileSignedURL";
import { useValidateExpenseAmountMutation } from "hooks/useValidateExpenseAmountMutation";
import SCREEN_NAME, { SCREEN_BOTTOM_TAB } from "navigation/ScreenName";

import { EditExpenseScreenRouteProp } from "navigation/type";
import { saveRecentCompanyTeamToStorage } from "utils";
import { CLIENT_ERROR_CODE, SERVER_ERROR_CODE } from "utils/serverCode";

import ExpenseForm from "../components/ExpenseForm/ExpenseForm";
import { formatDataImages } from "../components/ExpenseForm/helpers";
import {
  ExpenseFormAction,
  ExpenseFormValues,
  LOCATION_TYPE,
  MobileInsertExpenseMutationVariables,
} from "../components/ExpenseForm/types";
import {
  ACCOUNT_TYPES,
  COMPANY_ATTACHMENT_OBJECT_TYPES,
  COMPANY_ATTACHMENT_TYPES,
  EXPENSE_ATTACHMENT_TYPE,
  RESTRICTION_BUDGET_TYPE,
  TRACKING_OBJECT_TYPE,
} from "constants/constants";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { ApolloError } from "@apollo/client";
import { useNavigation } from "@react-navigation/native";
import { goBack } from "navigation/RootNavigation";
import { analyticService, withTrackingSession } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import { AlertBudgetTracking } from "screens/ExpenseRequest/types";
import { useMasterData } from "contexts/MasterDataContext";
import { useInsertCompanyAttachments } from "screens/Expense/hooks/useInsertCompanyAttachments";
import { useDeleteCompanyAttachments } from "screens/Expense/hooks/useDeleteCompanyAttachments";
import { executeRouteFunction } from "utils/route";

type EditExpenseScreenProps = {
  route: EditExpenseScreenRouteProp;
};

const EditExpenseScreen: FC<EditExpenseScreenProps> = ({ route }) => {
  const [approverId, setApproverId] = useState<string | undefined>(undefined);
  const navigation = useNavigation<any>();

  const { expenseId = "" } = route?.params ?? {};
  const alertNotificationRef = useRef<AlertNotificationHandle>(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // state to disabled button when user first click

  const { user, company } = useAuth();
  const { setting } = useMasterData();
  const isRequiredPaymentInfo = setting?.requiredPaymentInfo;
  const isTLNExpenseCompany = useMemo(
    () =>
      [
        COMPANIES.TLN_EXPENSE_COMPANY_SCRUM,
        COMPANIES.TLN_EXPENSE_COMPANY_DEV,
        COMPANIES.TLN_EXPENSE_COMPANY_PRODUCTION,
      ].includes(company?.company_id),
    [company?.company_id]
  );
  const isReady = useInteractionManager();
  const { uploadImage } = useUploadFileSignedURL();
  const [updateExpense] = useExpUpdateExpenseMutation();
  const [deleteExpense] = useDeleteExpense();
  const [submitExpenseReport] = useExpSubmitExpenseReportMutation();
  const [insertExpenseReport] = useExpInsertExpenseReportMutation();
  const [deleteExpenseAttachments] = useExpDeleteExpenseAttachments();
  const [insertExpenseAttachments] = useExpInsertExpenseAttachments();
  const [insertCompanyAttachments] = useInsertCompanyAttachments();
  const [deleteCompanyAttachments] = useDeleteCompanyAttachments();

  //const { getInvoice, dataInvoice } = useInvoiceByPkQuery({ invoiceId });
  const [validateExpenseBudgetAmount] = useValidateExpenseAmountMutation();
  const { t } = useTranslation("app/screens/Expense/EditExpenseScreen");
  const { expenseData, expenseQuery, loading } = useExpExpenseDetailsQuery({ variables: { expenseId } });
  const [isDirty, setIsDirty] = useState(false);

  const handleError = useCallback(
    (error?: ApolloError, errorTitle = "") => {
      setIsSubmitting(false);
      let message = t("an_error_occurred_during_processing");
      if (error?.message === SERVER_ERROR_CODE.EXPENSE_NO_APPROVAL_FLOW) {
        message = t("description_error_send_report");
      } else if (error?.message === SERVER_ERROR_CODE.INVALID_COMPANY_TEAM_ID) {
        message = t("team_in_valid_error");
      } else if (error?.graphQLErrors?.[0]?.extensions?.code === SERVER_ERROR_CODE.BAD_USER_INPUT) {
        message = error?.graphQLErrors?.[0]?.message;
      }
      alertNotificationRef.current?.error({
        onCancel: () => goBack(),
        title: t(errorTitle as any),
        description: message,
      });
    },
    [navigation, t]
  );

  const handleSubmitExpenseReport = async (note?: string) => {
    try {
      const { data: expenseReportData } = await insertExpenseReport({
        variables: {
          expenseReport: {
            expenseIds: [expenseId],
            title: note ?? "",
          },
        },
      });
      const expenseReportId = expenseReportData?.expInsertExpenseReport.expenseReportId;
      if (!expenseReportId) {
        throw new Error(CLIENT_ERROR_CODE.CREATE_EXPENSE_REPORT_FAIL);
      }
      await submitExpenseReport({
        variables: {
          expenseReportId,
          approverEmployeeId: approverId,
        },
      });
      alertNotificationRef.current?.close();
      analyticService.logEvent({ sessionId: SCREEN_NAME.EditExpenseScreen, name: EVENT.REPORT.SUBMITTED_SUCCESS });
      ToastManager.success(t("expense_submission_successfully"));
      goBack();
      executeRouteFunction(route, "onRefreshData");
    } catch (error) {
      analyticService.logEvent({
        sessionId: SCREEN_NAME.EditExpenseScreen,
        name: EVENT.REPORT.SUBMITTED_FAILED,
        properties: {
          message: t("submit_failed"),
          error: JSON.stringify(error),
        },
      });
      handleError(error, "submit_failed");
    }
  };

  const handleUpdateExpense = async (type: ExpenseFormAction, values: ExpenseFormValues) => {
    try {
      const {
        invoice,
        expenseDate,
        vatRate,
        placeDetails,
        invoiceFiles,
        companyTeam,
        fileAttachments,
        request,
        note,
        category: expenseCategoryId,
        amount,
        cardTransaction,
        allocatedExpense,
      } = values;
      const { allocatedDocuments, allocatedExpenses, isOn: isEnableAllocatedExpense } = allocatedExpense ?? {};
      // flow save recent company team to storage
      if (companyTeam?.companyTeamId && companyTeam?.companyTeamId !== expenseData?.companyTeamId) {
        saveRecentCompanyTeamToStorage({
          companyId: company?.company_id,
          employeeId: user?.employee_id,
          companyTeam: companyTeam,
        });
      }
      const expenseHaveCardTransaction = Boolean(cardTransaction?.cardTransactionId) && cardTransaction?.isOn;
      const variables: MobileInsertExpenseMutationVariables = {
        expense: {
          note,
          address: invoice?.invoiceElectronic?.sellerAddressLine,
          provider: invoice?.invoiceElectronic?.sellerLegalName,
          invoiceId: invoice?.invoiceElectronic?.invoiceId || null,
          expenseDate,
          placeDetails: null,
          companyTeamId: companyTeam?.companyTeamId || null,
          expenseRequestId: request?.expenseRequestId || null,
          customFields: {},
          expenseCategoryId,
          totalVatAmount: vatRate ?? 0,
          totalAmountWithVat: Number(amount),
          invoiceType: invoice?.invoiceType,
          cardTransactionId: expenseHaveCardTransaction ? cardTransaction?.cardTransactionId : null,
          subAccount: values?.categorySubAccount ? values?.categorySubAccount : null,
          currency: values?.currency?.isOn ? values?.currency?.name : "VND",
          exchangeRate: values?.currency?.isOn ? values?.currency?.exchangeRate : null,
          foreignTotalAmountWithVat:
            values?.currency?.isOn && values?.foreignTotalAmountWithVat ? values?.foreignTotalAmountWithVat : null,
        },
      };
      if (isRequiredPaymentInfo) {
        variables.expense.placeDetails = placeDetails?.location_name
          ? {
              ...placeDetails,
              type: undefined, //remove field type
            }
          : null;
      } else {
        variables.expense.placeDetails =
          placeDetails?.type === LOCATION_TYPE.AVAILABLE
            ? {
                ...placeDetails,
                type: undefined, //remove field type
              }
            : null;
      }
      let newAttachments = [];
      const currentFileAttachments = formatDataImages(
        EXPENSE_ATTACHMENT_TYPE.DOCUMENT,
        expenseData?.expenseAttachments
      );
      const newFileAttachments = fileAttachments?.filter(
        (file) => !currentFileAttachments?.find((i) => file?.name === i?.name)
      );
      const deleteFileAttachments = currentFileAttachments?.filter(
        (file) => !fileAttachments?.find((i) => file?.name === i?.name)
      );
      if (!isEmpty(newFileAttachments)) {
        const data = await uploadImage({
          type: EXPENSE_ATTACHMENT_TYPE.DOCUMENT,
          objectId: expenseId,
          images: newFileAttachments,
          companyId: company.company_id,
          subAttachmentType: AttachmentType.TYPE_REPORT,
        });
        newAttachments = data.filter(Boolean);
      }
      const currentInvoiceImages = formatDataImages(EXPENSE_ATTACHMENT_TYPE.INVOICE, expenseData?.expenseAttachments);
      const newInvoiceImages = invoiceFiles?.filter(
        (file) => !currentInvoiceImages?.find((i) => file?.name === i?.name)
      );
      const deleteInvoiceImages = currentInvoiceImages?.filter(
        (file) => !invoiceFiles?.find((i) => file?.name === i?.name)
      );
      if (invoice?.invoiceType === InvoiceType.PAPER && !isEmpty(newInvoiceImages)) {
        const data = await uploadImage({
          objectId: expenseId,
          companyId: company.company_id,
          images: newInvoiceImages,
          type: EXPENSE_ATTACHMENT_TYPE.INVOICE,
          subAttachmentType: AttachmentType.TYPE_REPORT,
        });
        newAttachments = [...newAttachments, ...data.filter(Boolean)];
      }
      if (!isEmpty(newAttachments)) {
        const attachments = newAttachments.map((value) => {
          return { ...value, expense_id: expenseId };
        });
        await insertExpenseAttachments({
          variables: {
            attachments,
          },
        });
      }
      //NEW
      const newAllocatedDocuments = allocatedDocuments?.filter((item) => !item.uri.startsWith("http"));
      const currentAllocatedDocuments = expenseData?.companyAttachments?.filter(
        (item) => item?.attachmentType === COMPANY_ATTACHMENT_TYPES.ALLOCATED_DOCUMENT
      );
      const removeAllocatedDocuments = isEnableAllocatedExpense
        ? currentAllocatedDocuments?.filter(
            (file) => !allocatedDocuments?.find((i) => file?.companyAttachmentId === i?.id)
          )
        : currentAllocatedDocuments;
      if (!isEmpty(newAllocatedDocuments) && isEnableAllocatedExpense) {
        const files = await uploadImage({
          objectId: expenseId,
          images: allocatedDocuments,
          companyId: company.company_id,
          subAttachmentType: AttachmentType.TYPE_REPORT,
        });
        const { file_name: fileName, file_url: fileUrl, file_type: filetype, file_size: fileSize } = files[0];
        await insertCompanyAttachments({
          variables: {
            input: {
              companyId: company.company_id,
              objectId: expenseId,
              objectType: COMPANY_ATTACHMENT_OBJECT_TYPES.EXPENSE,
              attachments: [
                {
                  attachmentType: COMPANY_ATTACHMENT_TYPES.ALLOCATED_DOCUMENT as any,
                  fileUrl,
                  thumbnailUrl: fileUrl,
                  fileName,
                  fileSize,
                  mimeType: filetype,
                  customFields: {
                    allocatedExpenses,
                  },
                },
              ],
            },
          },
        });
      }
      if (!isEmpty(removeAllocatedDocuments)) {
        await deleteCompanyAttachments({
          variables: {
            input: {
              attachmentIds: [removeAllocatedDocuments[0].companyAttachmentId],
              companyId: company.company_id,
              objectId: expenseId,
              objectType: COMPANY_ATTACHMENT_OBJECT_TYPES.EXPENSE,
            },
          },
        });
      }
      const removedAttachments = [...deleteFileAttachments, ...deleteInvoiceImages];
      if (!isEmpty(removedAttachments)) {
        await deleteExpenseAttachments({
          variables: {
            deleteAttachmentsExp: {
              expense_attachment_id: {
                _in: removedAttachments.map(({ expenseAttachmentId }) => expenseAttachmentId),
              },
            },
          },
        });
      }
      if (isTLNExpenseCompany) {
        const { vatRate, taxCode, invoiceCode } = values;
        const month =
          dayjs(expenseDate).month() + 1 < 9 ? `0${dayjs(expenseDate).month() + 1}` : dayjs(expenseDate).month() + 1;
        const year = dayjs(expenseDate).year().toString().substring(2);
        variables.expense.expenseCategoryId = undefined;
        variables.expense.customFields = {
          vat_rate: vatRate,
          invoice_code: invoiceCode,
          vat_period: `VAT${month}${year}`,
          tax_code: taxCode,
        };
      }
      /*--- flow save payment info to expense --> in the case have card transaction --> not send payment info ---*/
      variables.expense.paymentInfo = null;
      if (
        values?.paymentInfo?.type &&
        values?.paymentInfo?.type !== ACCOUNT_TYPES.NONE &&
        !expenseHaveCardTransaction
      ) {
        variables.expense.paymentInfo = {
          accountHolderName: values?.paymentInfo?.accountHolderName,
          accountNumber: values?.paymentInfo?.accountNumber,
          bankCode: values?.paymentInfo?.bank?.metadata?.bankCode,
          bankId: values?.paymentInfo?.bank?.metadata?.bankId,
          bankName: values?.paymentInfo?.bank?.metadata?.bankName,
          bankShortName: values?.paymentInfo?.bank?.metadata?.bankShortName,
          branchName: values?.paymentInfo?.branchName,
          description: values?.paymentInfo?.description,
          logoUrl: values?.paymentInfo?.bank?.metadata?.logoUrl,
          type: values?.paymentInfo?.type,
        };
      }
      const {
        data: {
          expUpdateExpense: { expenseReport },
        },
      } = await updateExpense({
        variables: { ...variables, expenseId },
      });
      setIsSubmitting(false);
      if (type === ExpenseFormAction.CREATE_OR_UPDATE_EXPENSE) {
        setIsDirty(false);
        analyticService.logEvent({
          sessionId: SCREEN_NAME.EditExpenseScreen,
          name: EVENT.EXPENSE.UPDATED_SUCCESS,
        });
        ToastManager.success(t("update_expense_successfully"));
        if (expenseReport && expenseReport?.expenseReportId !== expenseData?.expenseReportId) {
          alertNotificationRef.current?.confirm({
            icon: <BizziBotInfo />,
            title: t("auto_add_expense_to_report_title"),
            description: t("auto_add_expense_to_report_description"),
            cancelText: t("view_report"),
            confirmText: t("understood"),
            onCancel: () => {
              navigation.navigate(SCREEN_NAME.ReportDetailScreen, {
                expenseReportId: expenseReport?.expenseReportId,
                onRefreshData: () => {
                  executeRouteFunction(route, "onRefreshData");
                },
              });
            },
            onConfirm: () => {
              goBack();
              executeRouteFunction(route, "onRefreshData");
            },
          });
          return;
        }
        alertNotificationRef.current?.close();
        onNavigateToExpenseDetail();
        return;
      }
      handleSubmitExpenseReport(note);
    } catch (error) {
      handleError(error, "update_failed");
    }
  };

  const showAlert = useCallback(() => {
    alertNotificationRef.current?.warning({
      icon: <BizziBotWarning />,
      title: t("cancel_title"),
      description: t("cancel_confirm"),
      cancelText: t("close"),
      confirmText: t("cancel"),
      onConfirm: () => {
        alertNotificationRef.current?.close();
        goBack();
      },
    });
  }, [navigation, t]);

  const handleGoBack = useCallback(() => {
    if (!isDirty) {
      goBack();
      return;
    }
    showAlert();
  }, [isDirty, navigation, showAlert]);

  const handleSubmit = async (type: ExpenseFormAction, values: ExpenseFormValues) => {
    alertNotificationRef.current?.loading();
    setIsSubmitting(true);
    try {
      // in the case is TLN company: no need validate budget
      if (isTLNExpenseCompany) {
        await handleUpdateExpense(type, values);
        return;
      }
      /**
       * check budget validation before
       */
      const companyId = company.company_id;
      const { companyTeam, expenseDate, request, category, amount, invoice } = values;
      const spendingAmount = Number(amount);
      const { data } = await validateExpenseBudgetAmount({
        variables: {
          input: {
            companyId,
            expenseDate,
            companyTeamId: companyTeam?.companyTeamId,
            spendingAmount,
            spendingAmountWithVat: spendingAmount,
            spendingAmountWithoutVat: invoice?.invoiceElectronic?.totalAmountWithoutVat,
            expenseRequestId: request?.expenseRequestId,
            expenseCategoryId: category,
          },
        },
      });
      const validateExpenseAmountResult = data?.expValidateExpenseAmount;
      const validateBudget = validateExpenseAmountResult?.budget;
      const validateExpenseRequest = validateExpenseAmountResult?.expenseRequest;

      if (!validateExpenseAmountResult) {
        throw new Error(CLIENT_ERROR_CODE.CREATE_EXPENSE_REPORT_FAIL);
      }

      if (
        (validateBudget?.isValid || validateBudget?.restrictionType === RESTRICTION_BUDGET_TYPE.ACCEPT) &&
        (validateExpenseRequest?.isValid || validateExpenseRequest?.restrictionType === RESTRICTION_BUDGET_TYPE.ACCEPT)
      ) {
        await handleUpdateExpense(type, values);
        return;
      }

      setIsSubmitting(false);
      if (
        (!validateExpenseRequest?.isValid &&
          validateExpenseRequest?.restrictionType === RESTRICTION_BUDGET_TYPE.RESTRICT) ||
        (!validateBudget?.isValid && validateBudget?.restrictionType === RESTRICTION_BUDGET_TYPE.RESTRICT)
      ) {
        const properties: AlertBudgetTracking = {
          message: t("restrict_budget_expense"),
          restriction_type: RESTRICTION_BUDGET_TYPE.RESTRICT,
          object_type: TRACKING_OBJECT_TYPE.EXPENSE,
          over_budgets: [],
          over_requests: [],
        };
        if (!validateBudget?.isValid) {
          properties.over_budgets = [
            {
              expense_category_id: category,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: validateBudget?.overBudgetAmount,
              expense_date: expenseDate,
            },
          ];
        }
        if (validateExpenseRequest && !validateBudget.isValid) {
          properties.over_requests = [
            {
              expense_category_id: category,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: validateExpenseRequest?.overRequestAmount,
            },
          ];
        }
        analyticService.logEvent({
          name: EVENT.SYSTEM_ALERT.RESTRICT_EXPENSE_BUDGET,
          properties,
        });
        alertNotificationRef.current?.info({
          icon: <BizziBotWarning2 />,
          title: t("budget_amount_warning_title"),
          description: t("restrict_budget_expense"),
          confirmText: t("understood"),
          onConfirm: () => {
            alertNotificationRef?.current?.close();
          },
        });
        return;
      }

      const messages: string[] = [];
      if (
        !validateExpenseAmountResult.budget.isValid &&
        validateExpenseAmountResult.budget.restrictionType !== RESTRICTION_BUDGET_TYPE.ACCEPT
      ) {
        messages.push(validateExpenseAmountResult.budget.message);
      }
      if (
        !validateExpenseAmountResult.expenseRequest.isValid &&
        validateExpenseAmountResult.expenseRequest.restrictionType !== RESTRICTION_BUDGET_TYPE.ACCEPT
      ) {
        messages.push(validateExpenseAmountResult.expenseRequest.message);
      }
      messages.push(t("budget_amount_warning_context"));
      const properties: AlertBudgetTracking = {
        message: `${uniq(messages).join(".\n")}`,
        restriction_type: RESTRICTION_BUDGET_TYPE.WARNING,
        object_type: TRACKING_OBJECT_TYPE.EXPENSE,
      };
      if (!validateBudget.isValid) {
        properties.over_budgets = [
          {
            expense_category_id: category,
            company_team_id: companyTeam?.companyTeamId,
            over_amount: validateBudget.overBudgetAmount,
            expense_date: expenseDate,
          },
        ];
      }
      if (!validateExpenseRequest.isValid) {
        properties.over_requests = [
          {
            expense_category_id: category,
            company_team_id: companyTeam?.companyTeamId,
            over_amount: validateExpenseRequest.overRequestAmount,
          },
        ];
      }
      analyticService.logEvent({
        name: EVENT.SYSTEM_ALERT.WARNING_EXPENSE_BUDGET,
        properties,
      });
      alertNotificationRef.current?.confirm({
        icon: <BizziBotWarning2 />,
        title: t("budget_amount_warning_title"),
        description: `${uniq(messages).join(".\n")}`,
        cancelText: t("back"),
        confirmText: t("confirm"),
        onCancel: () => {
          alertNotificationRef.current?.close();
        },
        onConfirm: () => {
          alertNotificationRef.current?.loading();
          handleUpdateExpense(type, values);
        },
      });
      setIsSubmitting(false);
    } catch (error) {
      handleError(error, "update_failed");
    }
  };

  const onDeleteExpense = useCallback(async () => {
    try {
      alertNotificationRef.current?.loading();
      await deleteExpense({
        variables: {
          expenseId,
          employeeId: user.employee_id,
        },
      });
      alertNotificationRef.current?.close();
      ToastManager.success(t("delete_expense_successfully"));
      executeRouteFunction(route, "onRefreshData");
      navigation.navigate(SCREEN_BOTTOM_TAB.ExpensesScreen);
    } catch (error) {
      handleError(error, "delete_failed");
    }
  }, [deleteExpense, expenseId, handleError, navigation, t, user.employee_id]);

  const onNavigateToExpenseDetail = useCallback(() => {
    executeRouteFunction(route, "onRefreshData");
    navigation.pop();
    navigation.navigate(SCREEN_NAME.ExpenseDetailScreen, { expenseId });
  }, [navigation]);

  const isExpenseFromTransaction = expenseData?.expenseCardTransactions?.[0];

  useEffect(() => {
    expenseQuery();
  }, []);

  useBackHandler(() => {
    if (isDirty) {
      showAlert();
      return true;
    }
    return false;
  });

  return (
    <BottomSheetModalProvider>
      <BackHeader
        headerTitle={t("update_expense")}
        headerRight={
          loading || isExpenseFromTransaction ? null : (
            <TouchableOpacity
              onPress={() => {
                alertNotificationRef.current?.warning({
                  cancelText: t("close"),
                  confirmText: t("confirm"),
                  title: t("delete_expense"),
                  description: t("delete_expense_description"),
                  onConfirm: onDeleteExpense,
                });
              }}
            >
              <TrashIcon />
            </TouchableOpacity>
          )
        }
        onPress={handleGoBack}
      />
      {!isReady || loading ? (
        <LoadingView />
      ) : (
        <View style={styles.container}>
          <ExpenseForm
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            saveDraftText={t("save_draft")}
            allowSendReport={isTLNExpenseCompany}
            expenseDetail={expenseData}
            approverId={approverId}
            setApproverId={setApproverId}
            setIsDirty={setIsDirty}
          />
          <AlertNotification goBackIsDismiss ref={alertNotificationRef} />
        </View>
      )}
    </BottomSheetModalProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default withTrackingSession(EditExpenseScreen, SCREEN_NAME.EditExpenseScreen);
