import { ISelectCustomItem } from "components/InputCustom/SelectCustom";
import { InvoiceType } from "constants/ExpenseApproval";
import { ACCOUNT_TYPES, TRANSACTION_CARD_STATUS } from "constants/constants";
import { MobileExpenseDetailsByPkQuery } from "types";

export interface ImageInfo {
  uri: string;
  id: string;
  name?: string;
  type?: string;
  fileType?: string;
  expenseAttachmentId?: string;
  createdAt?: Date;
  fileSize?: number;
}

enum StatusVerify {
  SUCCESS = "success",
  ERROR = "error",
}
export enum ExpenseFormAction {
  SUBMIT_REPORT = "SUBMIT_REPORT",
  CREATE_OR_UPDATE_EXPENSE = "CREATE_OR_UPDATE_EXPENSE",
}
export interface ExpenseFormProps {
  isSubmitting?: boolean;
  saveDraftText: string;
  allowSendReport?: boolean;
  onSubmit: (type: ExpenseFormAction, params: ExpenseFormValues) => void;
  autoFocusAmountInput?: boolean;
  approverId?: string;
  setApproverId: (id: string) => void;
  expenseDetail?: MobileExpenseDetailsByPkQuery["expExpenseDetails"];
  setIsDirty?: (bool: boolean) => void;
  defaultValue?: {
    amount?: number;
    cardTransaction?: any;
    invoiceId?: string;
    invoiceType?: InvoiceType;
    photos: ImageInfo[];
    attachments: ImageInfo[];
    companyTeam?: { companyTeamId: string; code: string; name?: string; nameEn?: string };
  };
  shouldShowWalkthrough?: boolean;
}

export type ExpenseFormValues = {
  foreignTotalAmountWithVat?: number;
  amount: string;
  companyTeam?: { companyTeamId: string; code: string; name?: string; nameEn?: string };
  category: string;
  request: {
    expenseRequestId: string;
    docSequence?: string;
    title?: string;
    isAdvanceRequest?: boolean;
  };
  note: string;
  expenseDate: string;
  placeDetails?: {
    type?: LOCATION_TYPE;
    location_name: string | null;
    location_address: string | null;
    latitude?: number;
    longitude?: number;
  };
  fileAttachments: ImageInfo[];
  invoice?: {
    invoiceType: InvoiceType;
    invoiceElectronic?: {
      // in the case select electronic invoice
      invoiceId: string;
      sellerLegalName?: string;
      vatRate?: number;
      totalAmountWithVat?: number;
      totalAmountWithoutVat?: number;
      sellerAddressLine?: string;
      receivedAt: string;
      invoiceNumber?: string;
      invoiceItems: Array<{ invoiceItemId: string; itemName?: string }>;
      invoiceValidations: Array<{
        invoiceValidationId: string;
        gdtResults?: any;
        validationAggregation?: {
          status: StatusVerify;
        };
        expenseValidations?: {
          status: StatusVerify;
        };
      }>;
    };
  };
  invoiceFiles?: ImageInfo[]; // in the case select paper invoice
  taxCode?: string;
  invoiceCode?: string;
  vatRate?: number;
  paymentInfo?: {
    type?: ACCOUNT_TYPES;
    bank?: { key?: string; label?: string; metadata?: BankAccount };
    accountNumber?: string;
    accountHolderName?: string;
    bankName?: string;
    bankShortName?: string;
    branchName?: string;
    description?: string;
  };
  customField?: {
    taxCode: string;
    invoiceCode: string;
    vatRate: string;
  };
  cardTransaction: {
    isOn: boolean;
    cardTransactionId: string;
    transactionDate?: string;
    card?: {
      prefixCardNumber?: string;
      suffixCardNumber?: string;
    };
    location?: string;
    unreconciledAmount: number;
    amount: number;
    status: TRANSACTION_CARD_STATUS;
    cardTransactionExpenses?: { expenseId: string; expense?: { docSequence: string } }[];
  };
  allocatedExpense: {
    isOn: boolean;
    allocatedDocuments?: ImageInfo[];
    allocatedExpenses?: AllocatedExpenseItem[];
  };
  categorySubAccount?: { accountCode: string; title: string };
  currency?: { isOn: boolean; name: string; exchangeRate: number };
};

export type PaymentInfoFormValues = {
  description?: string;
  bankOption?: ISelectCustomItem;
  accountNumber?: string;
  accountHolderName?: string;
  branchName?: string;
};

export type BankAccount = {
  accountHolderName?: string;
  accountNumber?: string;
  type?: "enterprise" | "personal";
  bankCode?: string;
  bankId?: string;
  bankName?: string;
  bankShortName?: string;
  branchName?: string;
  description?: string;
  logoUrl?: string;
};
export enum LOCATION_TYPE {
  NONE = "NONE",
  AVAILABLE = "AVAILABLE",
}

export type AllocatedExpenseItem = {
  description?: string;
  uom?: string;
  amount?: string;
  customer?: string;
  cost_center?: string;
  bu_code?: string;
  invoice_number?: string;
  task_code?: string;
};

export type MobileInsertExpenseMutationVariables = {
  expense: {
    address?: string;
    cardTransactionId?: string;
    category?: string;
    /** 1: electronic, 2: paper, 3: no invoice */
    companyEmployeeId?: string;
    companyId?: string;
    companyTeamId?: string;
    customFields?: Record<string, any>;
    expenseCategoryId?: string;
    expenseDate: string;
    expenseInfo?: Record<string, any>;
    expenseRequestId?: string;
    expenseTravelId?: string;
    invoiceId?: string;
    invoiceType: number;
    note?: string;
    paymentInfo?: ExpExpensePaymentInfoCreateInput;
    placeDetails?: Record<string, any>;
    provider?: string;
    totalAmountWithVat: number;
    totalAmountWithoutVat?: number;
    totalVatAmount?: number;
    subAccount?: { accountCode: string; title: string };
    currency?: string;
    exchangeRate?: number;
    foreignTotalAmountWithVat?: number;
  };
};
