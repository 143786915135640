import { SubmitErrorHandler, useForm } from "react-hook-form";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, TouchableOpacity, View } from "react-native";
import { ExpenseHelper } from "utils/expense";
import i18n from "i18next";

import { Flex } from "@ant-design/react-native";
import { BizziBotWarning2, InfoCircleIcon2, WarningCircleIcon, WarningCircleSmallIcon } from "assets/images/svg/icons";
import {
  AlertNotification,
  AlertNotificationHandle,
  ApproverChooserModal,
  ApproverChooserModalMethods,
  AppText,
  BottomSheetModalCustomMethods,
  Button,
  CompanyTeamSelect,
  CopilotStepCustom,
  CurrencyText,
  DatePickerCustom,
  FloatFooter,
  IconCustom,
  KeyboardAwareScrollViewCustom,
  SwitchButton,
  TextInputCustom,
  ToastManager,
} from "components";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import {
  APPROVAL_STATUS,
  APPROVAL_TYPE,
  CONSTANTS,
  EXPENSE_REPORT_STATUS,
  EXPENSE_STATUS,
  RESTRICTION_BUDGET_TYPE,
  TRACKING_OBJECT_TYPE,
} from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { useExpInsertExpenseReportMutation } from "hooks/reports/useExpInsertExpenseReportMutation";
import { useExpSubmitExpenseReportMutation } from "hooks/reports/useExpSubmitExpenseReportMutation";
import { useExpUpdateExpenseReportMutation } from "hooks/reports/useExpUpdateExpenseReportMutation";
import { useValidateExpenseReportAmountMutation } from "hooks/useValidateExpenseReportAmount";
import ExpenseListSection from "screens/Report/ReportDetailScreen/components/ExpenseListSection/ExpenseListSection";
import { Colors, Fonts, FontTypes } from "theme";
import { CLIENT_ERROR_CODE, SERVER_ERROR_CODE } from "utils/serverCode";
import PickerCustom from "components/InputCustom/PickerCustom";
import uniq from "lodash/uniq";
import TotalAmountInfo from "./TotalAmountInfo";
import ExpenseCashAdvanceInfoModal from "screens/Report/components/CreateOrEditForm/ExpenseCashAdvanceInfoModal";
import { startLayoutAnimation, exchangeRateConverter } from "utils";
import { goBack } from "navigation/RootNavigation";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";
import SCREEN_NAME from "navigation/ScreenName";
import { MobileValidateExpenseReportAmountMutation } from "types";
import { FeedbackObjectType, useInAppFeedback } from "contexts/InAppFeedbackContext";
import { BizziBotSticker01 } from "assets/images";
import { useCopilot } from "react-native-copilot";
import { DateModalInfoData } from "screens/ExpenseRequest/types";
import dayjs from "dayjs";
import LanguageStatus from "constants/LanguageStatus";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { executeRouteFunction } from "utils/route";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useMasterData } from "contexts/MasterDataContext";
import { StackNavigation } from "navigation/type";
import { PAYMENT_METHOD_ICON, PAYMENT_METHOD_TEXT } from "screens/Report/constants";
import CurrencySection from "screens/Expense/components/ExpenseForm/CurrencySection";

import ChooseExpenseListModal from "./ChooseExpenseListModal/ChooseExpenseListModal";

import { ChooseExpenseListModalMethods, CreateOrEditFormProps, ReportFormValues } from "./index.props";
import styles from "./styles";

const REPORT_TITLE_MAX_LENGTH = 500;

const CreateOrEditForm: FC<CreateOrEditFormProps> = ({
  id,
  formData,
  onChangeFormValues,
  onRefreshExpense,
  shouldShowWalkthrough,
}) => {
  const isUpdateScreen = Boolean(id);
  const { t } = useTranslation("app/screens/Report/components/CreateOrEditForm");

  const { company } = useAuth();
  const route = useRoute();
  const navigation = useNavigation<StackNavigation>();
  const alertNotificationRef = useRef<AlertNotificationHandle>(null);
  const approverChooserModalRef = useRef<ApproverChooserModalMethods>(null);
  const chooseExpenseListModalRef = useRef<ChooseExpenseListModalMethods>(null);
  const cashAdvanceInfoModalRef = useRef<BottomSheetModalCustomMethods>(null);
  const scrollViewRef = useRef<ScrollView>(null);
  const positionInput = useRef({});

  const [
    FEATURE_EXP_REPORT_EXPECTED_PAYMENT_DATE,
    FEATURE_TEAM_MGMT,
    FEATURE_EXP_CASH_ADVANCE,
    FEATURE_EXP_REIMBURSABLE_EXPENSE,
  ] = useIsFeaturesEnabled([
    FEATURE_FLAGS.FEATURE_EXP_REPORT_EXPECTED_PAYMENT_DATE,
    FEATURE_FLAGS.FEATURE_TEAM_MGMT,
    FEATURE_FLAGS.FEATURE_EXP_CASH_ADVANCE,
    FEATURE_FLAGS.FEATURE_EXP_REIMBURSABLE_EXPENSE,
  ]);

  const {
    approvalTypeReport,
    setting: { paymentMethods },
  } = useMasterData();

  const [dateModalInfo, setDateModalInfo] = useState<DateModalInfoData>({
    visible: false,
    date: undefined,
    minimumDate: undefined,
    maximumDate: undefined,
  });
  const [footerHeight, setFooterHeight] = useState(0);
  const [disabledButton, setDisabledButton] = useState(false);
  const [hasCashAdvance, setHasCashAdvance] = useState(false);

  const [submitExpenseReport] = useExpSubmitExpenseReportMutation();
  const [updateExpenseReport] = useExpUpdateExpenseReportMutation();
  const [createExpenseReport] = useExpInsertExpenseReportMutation();
  const { requestInAppFeedback } = useInAppFeedback();

  /*-- start walkthrough flow --*/
  const { start, steps } = useCopilot();
  const stepNameToStartWalkthrough = Object.keys(steps).filter(
    (key) =>
      steps[key].walkthroughName === CONSTANTS.WALK_THROUGH.CREATE_REPORT.NAME &&
      steps[key].order === CONSTANTS.WALK_THROUGH.CREATE_REPORT.Step2
  )?.[0];

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<ReportFormValues>({
    shouldFocusError: false,
    defaultValues: {
      title: "",
      paymentMethod: "bank_transfers",
    },
    mode: CONSTANTS.COMMON.VALIDATE_RULE,
  });

  const expenseList = watch("expenseList", []);
  const approverId = watch("approverId");
  const paymentMethod = watch("paymentMethod");
  const companyTeam = watch("companyTeam");
  const exchangeRate = watch("currency.exchangeRate");
  const currency = watch("currency.name");

  const [validateExpenseReportAmount] = useValidateExpenseReportAmountMutation();

  const PAYMENT_METHOD_OPTIONS = useMemo(() => {
    return paymentMethods?.map((item) => ({
      label: PAYMENT_METHOD_TEXT[item],
      value: item,
      icon: PAYMENT_METHOD_ICON[item],
    }));
  }, [paymentMethods, t]);

  const rejectInfo = useMemo(() => {
    if (formData?.expenseApprovalLogs && formData.status === EXPENSE_REPORT_STATUS.REJECTED) {
      const approvalReject = formData?.expenseApprovalLogs.find((item) => item.status === APPROVAL_STATUS.REJECTED) || {
        reason: "",
      };
      if (approvalReject) {
        return { reason: approvalReject?.reason };
      }
    }
    return null;
  }, [formData]);

  /*-- scroll to first error when user submit form --*/
  const handleSetPositionInput = (key) => (offsetY) => {
    positionInput.current[key] = offsetY;
  };

  const onLayoutFooter = (event) => {
    const { height } = event.nativeEvent.layout;
    setFooterHeight(height);
  };
  const onError: SubmitErrorHandler<ReportFormValues> = (errors) => {
    const fieldFirstError = Object.keys(errors)?.[0];
    if (fieldFirstError && positionInput?.current?.[fieldFirstError]) {
      scrollViewRef?.current?.scrollTo({
        y: positionInput.current[fieldFirstError],
        animated: true,
      });
    }
    return errors;
  };
  /*--- end ---*/

  const handleSubmitReport = (isSave: boolean) => async () => {
    const [expenseList] = getValues(["expenseList"]);
    if (expenseList?.some((expense) => expense.status !== EXPENSE_STATUS.READY)) {
      alertNotificationRef.current?.info({
        icon: <WarningCircleIcon />,
        title: t("can_not_submit"),
        description: t("some_expenses_not_ready"),
        confirmText: t("understood"),
        onConfirm: () => {
          alertNotificationRef.current?.close();
        },
      });
      return;
    }
    if (!isSave && FEATURE_EXP_CASH_ADVANCE && hasCashAdvance) {
      alertNotificationRef.current.confirm({
        icon: <BizziBotWarning2 />,
        title: t("send_report_advance_title"),
        description: (
          <View style={{ marginBottom: 20 }}>
            <View style={styles.descriptionItem}>
              <View style={styles.dotIcon} />
              <AppText>{t("send_report_advance_description1")}</AppText>
            </View>
            <View style={styles.descriptionItem}>
              <View style={styles.dotIcon} />
              {payableAmount >= 0 ? (
                <AppText>
                  {t("company")} <AppText style={{ fontFamily: FontTypes.bold }}>{t("pays_you")}</AppText>{" "}
                  {t("pays_you_amount")}{" "}
                  <CurrencyText style={{ fontFamily: FontTypes.bold }}>{payableAmount}</CurrencyText>{" "}
                  {t("according_this_report")}
                </AppText>
              ) : (
                <AppText>
                  {t("you")} <AppText style={{ fontFamily: FontTypes.bold }}>{t("need_to_return")} </AppText>
                  {t("advance_amount_return")}
                  <CurrencyText style={{ fontFamily: FontTypes.bold }}>{payableAmount}</CurrencyText>{" "}
                  {t("according_this_report")}
                </AppText>
              )}
            </View>
          </View>
        ),
        confirmText: t("continue"),
        cancelText: t("cancel"),
        onConfirm: () => {
          if (approvalTypeReport === APPROVAL_TYPE.MANUAL) {
            alertNotificationRef?.current?.close();
            approverChooserModalRef?.current?.present();
            setDisabledButton(false);
            return;
          }
          onSubmit(isSave);
        },
        onCancel: () => {
          alertNotificationRef.current?.close();
        },
      });
      return;
    }
    setDisabledButton(true);
    /* in the case approve type is MANUAL */
    if (!isSave && approvalTypeReport === APPROVAL_TYPE.MANUAL) {
      approverChooserModalRef?.current?.present();
      setDisabledButton(false);
      return;
    }

    await onSubmit(isSave);
  };

  const onSubmit = async (isSave: boolean) => {
    const [expenseList] = getValues(["expenseList"]);
    setDisabledButton(true);
    if (approvalTypeReport === APPROVAL_TYPE.MANUAL) {
      approverChooserModalRef?.current?.close();
    }
    /*--- validate budget amount ---*/
    const expenseParamsValidate = expenseList
      .filter((item) => item?.totalAmountWithoutVat > 0)
      .map((item) => ({
        expenseDate: item?.expenseDate,
        spendingAmount: item?.totalAmountWithoutVat,
        spendingAmountWithVat: item?.totalAmountWithVat,
        spendingAmountWithoutVat: item?.totalAmountWithoutVat,
        expenseCategoryId: item?.expenseCategory?.expenseCategoryId,
        expenseRequestId: item?.expenseRequestId ?? undefined,
        companyTeamId: item?.companyTeamId ?? undefined,
      }));
    if (!isSave && expenseParamsValidate?.length > 0) {
      //in the case save draft -> no need validate budget amount
      alertNotificationRef?.current?.loading();
      const rs = await validateExpenseReportAmount({
        variables: {
          input: {
            companyId: company?.company_id,
            expenses: expenseParamsValidate,
          },
        },
      });
      setDisabledButton(false);
      const validateReport = rs?.data?.validateExpenseReportAmount;
      const isValidBudget =
        validateReport?.expenseRequests?.every(
          (item) => item?.isValid || item?.restrictionType === RESTRICTION_BUDGET_TYPE.ACCEPT
        ) &&
        validateReport?.budgets?.every(
          (item) => item?.isValid || item?.restrictionType === RESTRICTION_BUDGET_TYPE.ACCEPT
        );
      if (!isValidBudget) {
        const isRestrict =
          validateReport?.expenseRequests?.some(
            (item) => !item?.isValid && item?.restrictionType === RESTRICTION_BUDGET_TYPE.RESTRICT
          ) ||
          validateReport?.budgets?.some(
            (item) => !item?.isValid && item?.restrictionType === RESTRICTION_BUDGET_TYPE.RESTRICT
          );
        if (isRestrict) {
          analyticService.logEvent({
            name: EVENT.SYSTEM_ALERT.RESTRICT_REPORT_BUDGET,
            properties: {
              message: t("restrict_budget_report"),
              object_type: TRACKING_OBJECT_TYPE.REPORT,
              restriction_type: RESTRICTION_BUDGET_TYPE.RESTRICT,
              over_budgets: validateReport?.budgets?.map((item) => ({
                expense_category_id: item?.expenseCategoryId,
                company_team_id: companyTeam?.companyTeamId,
                over_amount: item?.overBudgetAmount,
                expense_date: item?.expenseDate,
              })),
              over_requests: validateReport?.expenseRequests?.map((item) => ({
                expense_category_id: item?.expenseCategoryId,
                company_team_id: companyTeam?.companyTeamId,
                over_amount: item?.overRequestAmount,
              })),
            },
          });
          alertNotificationRef?.current?.info({
            icon: <BizziBotWarning2 />,
            title: t("budget_amount_warning_title"),
            description: t("restrict_budget_report"),
            confirmText: t("understood"),
            onConfirm: () => {
              alertNotificationRef?.current?.close();
            },
          });
          return;
        }
        const messages = [];
        if (
          validateReport?.budgets?.[0] &&
          !validateReport.budgets[0].isValid &&
          validateReport.budgets[0].restrictionType !== RESTRICTION_BUDGET_TYPE.ACCEPT
        ) {
          messages.push(validateReport.budgets[0].message);
        }
        if (
          validateReport?.expenseRequests?.[0] &&
          !validateReport.expenseRequests[0].isValid &&
          validateReport.expenseRequests[0].restrictionType !== RESTRICTION_BUDGET_TYPE.ACCEPT
        ) {
          messages.push(validateReport.expenseRequests[0].message);
        }
        analyticService.logEvent({
          name: EVENT.SYSTEM_ALERT.WARNING_REPORT_BUDGET,
          properties: {
            restriction_type: RESTRICTION_BUDGET_TYPE.WARNING,
            object_type: TRACKING_OBJECT_TYPE.REPORT,
            message: `${uniq(messages).join(".\n")}`,
            over_budgets: validateReport?.budgets?.map((item) => ({
              expense_category_id: item?.expenseCategoryId,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: item?.overBudgetAmount,
              expense_date: item?.expenseDate,
            })),
            over_requests: validateReport?.expenseRequests?.map((item) => ({
              expense_category_id: item?.expenseCategoryId,
              company_team_id: companyTeam?.companyTeamId,
              over_amount: item?.overRequestAmount,
            })),
          },
        });
        messages.push(t("budget_amount_warning_context"));
        alertNotificationRef?.current?.confirm({
          icon: <BizziBotWarning2 />,
          title: t("budget_amount_warning_title"),
          description: `${uniq(messages).join(".\n")}`,
          cancelText: t("back"),
          onConfirm: () => {
            onCreateExpenseReport({
              isSave,
              validateReportResult: validateReport,
              message: `${uniq(messages).join(".\n")}`,
            });
          },
        });
        return;
      }
    }
    approverChooserModalRef.current?.close();
    /*--- no need validate budget amount ---*/
    await onCreateExpenseReport({ isSave });
  };

  const onSubmitManual = async () => {
    await onSubmit(false);
  };

  const onCreateExpenseReport = async ({
    isSave,
    validateReportResult,
    message,
  }: {
    isSave: boolean;
    validateReportResult?: MobileValidateExpenseReportAmountMutation["validateExpenseReportAmount"];
    message?: string;
  }) => {
    setDisabledButton(true);
    try {
      alertNotificationRef?.current?.loading();
      const [title, paymentMethod, companyTeam, approverId, expectedPaymentDate] = getValues([
        "title",
        "paymentMethod",
        "companyTeam",
        "approverId",
        "expectedPaymentDate",
      ]);
      const body = {
        title: title,
        companyTeamIds: companyTeam ? [companyTeam.companyTeamId] : undefined,
        paymentMethod,
        expenseIds: expenseList?.map((i) => i?.expenseId) ?? [],
        expectedPaymentDate,
        foreignExchangeRate: exchangeRate,
        currency,
      };
      let result;
      if (isUpdateScreen) {
        result = await updateExpenseReport({
          variables: {
            expenseReportId: id,
            expenseReport: body,
          },
        });
      } else {
        result = await createExpenseReport({
          variables: {
            expenseReport: body,
          },
        });
        if (!isSave) {
          analyticService.logEvent({
            name: EVENT.REPORT.CREATED_SUCCESS,
          });
          analyticService.logEvent({
            name: EVENT.REPORT.SUBMITTED,
          });
        }
      }

      if (!result?.data) {
        throw new Error(CLIENT_ERROR_CODE.CREATE_EXPENSE_REPORT_FAIL);
      }

      if (isSave) {
        alertNotificationRef?.current?.close();
        analyticService.logEvent({
          sessionId: isUpdateScreen ? SCREEN_NAME.EditReportScreen : SCREEN_NAME.NewReportScreen,
          name: isUpdateScreen ? EVENT.REPORT.UPDATED_SUCCESS : EVENT.REPORT.SAVED_DRAFT_SUCCESS,
        });
        ToastManager.success(t("save_success"));
        executeRouteFunction(route, "onRefreshData");
        goBack();
        setDisabledButton(false);
        return;
      }

      /* if not save draft -> submit expense report */
      const { expenseReportId } = result.data.expInsertExpenseReport || result?.data?.expUpdateExpenseReport;
      await submitExpenseReport({
        variables: {
          expenseReportId: expenseReportId,
          approverEmployeeId: approverId,
        },
      });
      alertNotificationRef?.current?.close();
      analyticService.logEvent({
        sessionId: isUpdateScreen ? SCREEN_NAME.EditReportScreen : SCREEN_NAME.NewReportScreen,
        name: EVENT.REPORT.SUBMITTED_SUCCESS,
        properties: {
          message,
          object_id: expenseReportId,
          object_type: TRACKING_OBJECT_TYPE.REPORT,
          over_budgets: validateReportResult?.budgets?.map((item) => ({
            expense_category_id: item?.expenseCategoryId,
            company_team_id: companyTeam?.companyTeamId,
            over_amount: item?.overBudgetAmount,
            expense_date: item?.expenseDate,
          })),
          over_requests: validateReportResult?.expenseRequests?.map((item) => ({
            expense_category_id: item?.expenseCategoryId,
            company_team_id: companyTeam?.companyTeamId,
            over_amount: item?.overRequestAmount,
          })),
        },
      });
      ToastManager.success(t("submit_expense_report_success"));
      executeRouteFunction(route, "onRefreshData");
      goBack();
      setDisabledButton(false);
      if (!isUpdateScreen) {
        setTimeout(() => {
          requestInAppFeedback(FeedbackObjectType.ExpenseReport);
        }, 1000);
      }
    } catch (error) {
      setDisabledButton(false);
      let message = i18n.t("common:internal_error");
      if (
        (error as Error)?.message === SERVER_ERROR_CODE.EXPENSE_NO_APPROVAL_FLOW ||
        (error as Error)?.message === SERVER_ERROR_CODE.EXPENSE_UNSUPPORTED_APPROVAL_METHOD
      ) {
        if (!isUpdateScreen) {
          executeRouteFunction(route, "onRefreshData");
        }
        message = t("description_error_send_report");
      } else if (error?.graphQLErrors?.[0]?.extensions?.code === SERVER_ERROR_CODE.BAD_USER_INPUT) {
        message = error?.graphQLErrors?.[0]?.message;
      }
      const noApprovalFlowError = [
        SERVER_ERROR_CODE.EXPENSE_NO_APPROVAL_FLOW,
        SERVER_ERROR_CODE.EXPENSE_UNSUPPORTED_APPROVAL_METHOD,
      ].includes(error?.message);

      alertNotificationRef?.current?.error({
        title: t("submit_expense_report_failed"),
        description: message,
        confirmText: noApprovalFlowError ? t("view_approval_flow") : undefined,
        onConfirm: noApprovalFlowError
          ? () => {
              alertNotificationRef?.current?.close();
              if (isUpdateScreen) {
                navigation.navigate(SCREEN_NAME.ApprovalFlowScreen, { index: 1 });
                return;
              }
              navigation.replace(SCREEN_NAME.ApprovalFlowScreen, { index: 1 });
            }
          : undefined,
      });

      analyticService.logEvent({
        sessionId: isUpdateScreen ? SCREEN_NAME.EditReportScreen : SCREEN_NAME.NewReportScreen,
        name: isSave ? EVENT.REPORT.UPDATED_FAILED : EVENT.REPORT.SUBMITTED_FAILED,
        properties: {
          message,
          error: JSON.stringify(error),
        },
      });
    }
  };

  const handleOpenExpenseListModal = () => {
    chooseExpenseListModalRef?.current?.present(companyTeam?.companyTeamId, expenseList);
  };

  const handleRemoveExpense = (item) => () => {
    const prevExpenseList = expenseList.filter((i) => i?.expenseId !== item?.expenseId);
    setValue("expenseList", prevExpenseList);
  };

  const onChoosePaymentMethod = (item, onSelect) => () => {
    setValue("paymentMethod", item?.value);
    onSelect();
  };
  const onRefreshExpenseList = () => {
    onRefreshExpense?.(expenseList?.map((i) => i?.expenseId));
  };

  const handleRemoveRequest = (requestId) => {
    setValue(
      "expenseList",
      getValues("expenseList")?.filter((item) => item.expenseRequestId !== requestId)
    );
  };

  const { totalAmountWithVat, totalCashAdvanceAmount, totalCardTransactionAmount, payableAmount } =
    ExpenseHelper.getExpensesRelevantAmounts(expenseList, exchangeRate);

  useEffect(() => {
    if (!formData) {
      return;
    }
    reset(
      {
        title:
          formData.title?.length > REPORT_TITLE_MAX_LENGTH
            ? `${formData.title?.slice(0, REPORT_TITLE_MAX_LENGTH - 3).trim()}...`
            : formData.title,
        companyTeam: formData.companyTeam,
        paymentMethod: formData.paymentMethod,
        currency: formData.currency,
        expenseList: formData.expenseList?.filter(
          (item) =>
            Boolean(item?.companyTeamId) === Boolean(formData.companyTeam?.companyTeamId) ||
            item?.companyTeamId === formData.companyTeam?.companyTeamId
        ),
      },
      { keepDirtyValues: true }
    );
    const isReportAdvance = formData?.expenseList?.some((item) => Boolean(item?.expenseRequest?.cashAdvances?.[0]));
    setHasCashAdvance(isReportAdvance);
  }, [formData]);

  useEffect(() => {
    const foreignExpenseInfo = ExpenseHelper.getForeignExpenseInfo(expenseList);
    setValue(
      "currency",
      foreignExpenseInfo.exchangeRate === -1
        ? null
        : {
            isOn: true,
            name: "USD",
            exchangeRate: foreignExpenseInfo.exchangeRate,
          }
    );
  }, [expenseList]);

  useEffect(() => {
    onChangeFormValues?.(isDirty);
  }, [isDirty, onChangeFormValues]);

  useEffect(() => {
    if (shouldShowWalkthrough && shouldShowWalkthrough !== "false" && stepNameToStartWalkthrough) {
      const step = steps[stepNameToStartWalkthrough];
      setTimeout(() => {
        start({
          step,
          walkthroughName: CONSTANTS.WALK_THROUGH.CREATE_REPORT.NAME,
        });
      }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
    }
  }, [stepNameToStartWalkthrough]);

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollViewCustom
        showsVerticalScrollIndicator={false}
        innerRef={(ref) => (scrollViewRef.current = ref)}
        contentContainerStyle={{ paddingBottom: footerHeight + 8 }}
      >
        <CopilotStepCustom
          title={"walkthrough_report_title_step2"}
          text={"walkthrough_report_description_step2"}
          order={CONSTANTS.WALK_THROUGH.CREATE_REPORT.Step2}
          image={BizziBotSticker01}
          walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_REPORT.NAME}
          style={styles.inputContainer}
          offsetX={10}
        >
          {Boolean(rejectInfo) && (
            <Flex style={styles.rejectReason}>
              <View style={styles.warningIcon}>
                <WarningCircleSmallIcon />
              </View>
              <View style={styles.flex}>
                <AppText numberOfLines={2} style={Fonts.BodyMedium}>{`${t("rejected")}: ${
                  rejectInfo?.reason
                }`}</AppText>
              </View>
            </Flex>
          )}
          <View style={styles.marginBottom20}>
            <AppText style={Fonts.SentenceSubtitleXLarge}>{t("general_info")}</AppText>
          </View>
          <TextInputCustom
            autoHeight
            multiline
            name="title"
            control={control}
            error={errors.title}
            label={t("title")}
            placeholder={t("title")}
            setPosition={handleSetPositionInput("title")}
            rules={{
              required: t("required"),
              minLength: { value: 5, message: t("explanation_min", { min: 5 }) },
              maxLength: { value: REPORT_TITLE_MAX_LENGTH },
            }}
          />
          {FEATURE_TEAM_MGMT && (
            <CompanyTeamSelect
              autoFillCompanyDefault={!id && formData?.companyTeam === undefined}
              onClear={() => {
                setValue("companyTeam", null);
                setValue("companyTeamId", null);
                setValue("expenseList", []);
              }}
              name="companyTeam"
              control={control}
              style={styles.marginVertical}
              setValue={setValue}
              onSelect={(companyTeamId) => {
                if (companyTeamId !== companyTeam?.companyTeamId) {
                  setValue("expenseList", []);
                }
              }}
            />
          )}
          <PickerCustom
            style={styles.dropdownSelect}
            title={t("payment_method")}
            control={control}
            error={errors.paymentMethod}
            name="paymentMethod"
            label={t("payment_method")}
            options={PAYMENT_METHOD_OPTIONS}
            renderPickerItem={(item, onSelect) => {
              const isActive = item?.value === paymentMethod;
              return (
                <TouchableOpacity
                  key={item.value}
                  style={[styles.paymentItem, isActive && { backgroundColor: Colors.primary0 }]}
                  onPress={onChoosePaymentMethod(item, onSelect)}
                >
                  <item.icon style={styles.optionIcon} />
                  <AppText style={[Fonts.BodyLarge, styles.centerContent]}>{item?.label}</AppText>
                </TouchableOpacity>
              );
            }}
          />
          {FEATURE_EXP_REPORT_EXPECTED_PAYMENT_DATE && (
            <DatePickerCustom
              style={{ marginTop: 20 }}
              name="expectedPaymentDate"
              control={control}
              label={t("expected_payment_date")}
              placeholder={t("expected_payment_date")}
              onPress={() =>
                setDateModalInfo({
                  visible: true,
                  date: getValues("expectedPaymentDate"),
                })
              }
            />
          )}
        </CopilotStepCustom>

        <View style={[styles.expenseListContainer, { paddingBottom: 20, paddingHorizontal: 16 }]}>
          <CurrencySection
            control={control}
            setValue={setValue}
            watch={watch}
            errors={errors}
            getValues={getValues}
            enableToggle={true}
          />
        </View>

        <View style={styles.expenseListContainer}>
          <View style={styles.expenseListTitle}>
            <AppText style={Fonts.SentenceSubtitleXLarge}>{t("list_of_expenses")}</AppText>
            {expenseList.length > 0 && (
              <TouchableOpacity onPress={handleOpenExpenseListModal}>
                <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
                  {t("edit_expense")}
                </AppText>
              </TouchableOpacity>
            )}
          </View>
          {FEATURE_EXP_CASH_ADVANCE && (
            <CopilotStepCustom
              title={"walkthrough_report_title_step4"}
              text={"walkthrough_report_description_step4"}
              order={CONSTANTS.WALK_THROUGH.CREATE_REPORT.Step4}
              image={BizziBotSticker01}
              walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_REPORT.NAME}
              style={styles.reportHasAdvance}
              offsetX={20}
            >
              <View style={styles.reportHasAdvanceInfo}>
                <AppText style={Fonts.BodyLarge} color={Colors.grayscale80}>
                  {t("report_has_advance")}
                </AppText>
                <TouchableOpacity style={{ marginTop: 2 }} onPress={() => cashAdvanceInfoModalRef?.current?.present()}>
                  <IconCustom name="info" />
                </TouchableOpacity>
              </View>
              <SwitchButton
                onValueChange={(value) => {
                  setValue("expenseList", []);
                  setHasCashAdvance(value);
                  startLayoutAnimation();
                }}
                value={hasCashAdvance}
              />
            </CopilotStepCustom>
          )}
          {hasCashAdvance && FEATURE_EXP_CASH_ADVANCE && (
            <View style={styles.descriptionAdvance}>
              <InfoCircleIcon2 color={Colors.warning50} />
              <AppText style={[Fonts.BodyMedium, styles.flex]} color={Colors.grayscale80}>
                {t("choose_request_has_advance")}
              </AppText>
            </View>
          )}

          {expenseList.length === 0 && (
            <CopilotStepCustom
              title={"walkthrough_report_title_step3"}
              text={"walkthrough_report_description_step3"}
              order={CONSTANTS.WALK_THROUGH.CREATE_REPORT.Step3}
              image={BizziBotSticker01}
              walkthroughName={CONSTANTS.WALK_THROUGH.CREATE_REPORT.NAME}
              style={{ paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, marginBottom: 20 }}
              offsetX={20}
              borderRadius={10}
            >
              <Button
                type="secondary"
                onPress={handleOpenExpenseListModal}
                icon={() => <IconCustom name="add-circle-outline" width={32} height={32} />}
              >
                {t("add_expense")}
              </Button>
            </CopilotStepCustom>
          )}
          {expenseList.length > 0 && (
            <ExpenseListSection
              onRefreshData={onRefreshExpenseList}
              isValidateBudget
              data={{ expenses: expenseList }}
              showTitle={false}
              handleRemoveExpense={hasCashAdvance ? null : handleRemoveExpense}
              handleRemoveRequest={hasCashAdvance ? handleRemoveRequest : null}
              foreignExchangeRate={exchangeRate}
            />
          )}
          {expenseList?.length > 0 && FEATURE_EXP_REIMBURSABLE_EXPENSE && (
            <TotalAmountInfo
              totalAmount={totalAmountWithVat}
              cashAdvanceAmount={totalCashAdvanceAmount}
              cardTransactionAmount={totalCardTransactionAmount}
              foreignExchangeRate={exchangeRate}
            />
          )}
        </View>
      </KeyboardAwareScrollViewCustom>

      <FloatFooter onLayout={onLayoutFooter}>
        {!!totalAmountWithVat && (
          <>
            <Flex justify="between" style={styles.totalAmountContainer}>
              <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
                {t("total_amount")}
              </AppText>
              <CurrencyText
                style={Fonts.NumericN400}
                color={Colors.success100}
                foreignAmount={exchangeRateConverter(totalAmountWithVat, exchangeRate)}
              >
                {totalAmountWithVat}
              </CurrencyText>
            </Flex>
            {FEATURE_EXP_REIMBURSABLE_EXPENSE && (
              <Flex justify="between" style={styles.paidAmountContainer}>
                <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
                  {payableAmount >= 0 ? t("company_must_pay") : t("employee_must_pay")}
                </AppText>
                <CurrencyText
                  style={Fonts.NumericN300}
                  foreignAmount={exchangeRateConverter(payableAmount, exchangeRate)}
                >
                  {payableAmount}
                </CurrencyText>
              </Flex>
            )}
          </>
        )}
        <View style={styles.buttonContainer}>
          <Button
            eventName={isUpdateScreen ? EVENT.REPORT.UPDATED : EVENT.REPORT.SAVED_DRAFT}
            type="secondary"
            disabled={disabledButton}
            style={styles.buttonSaveDaft}
            onPress={handleSubmit(handleSubmitReport(true), onError)}
          >
            {t("save")}
          </Button>
          <Button
            eventName={isUpdateScreen ? EVENT.REPORT.SUBMITTED : EVENT.REPORT.CREATED}
            style={styles.buttonSubmit}
            disabled={expenseList?.length <= 0 || disabledButton}
            onPress={handleSubmit(handleSubmitReport(false), onError)}
          >
            {t("send_report")}
          </Button>
        </View>
      </FloatFooter>

      <ChooseExpenseListModal
        companyTeam={companyTeam}
        hasCashAdvance={hasCashAdvance}
        expenseList={expenseList}
        setExpenseList={(expenses) => setValue("expenseList", expenses)}
        ref={chooseExpenseListModalRef}
      />
      <ApproverChooserModal
        isRequired
        title={t("select_approver")}
        description={t("select_approver_description")}
        ref={approverChooserModalRef}
        approverId={approverId}
        onSubmit={handleSubmit(onSubmitManual, onError)}
        onApproverId={(approverId: string) => setValue("approverId", approverId)}
      />
      <AlertNotification ref={alertNotificationRef} />
      <ExpenseCashAdvanceInfoModal ref={cashAdvanceInfoModalRef} />
      <DateTimePickerModal
        isVisible={dateModalInfo?.visible}
        onConfirm={(date) => {
          setDateModalInfo((prevState) => ({ ...prevState, visible: false, date }));
          setValue("expectedPaymentDate", new Date(date) as any, {
            shouldValidate: true,
          });
        }}
        date={getValues("expectedPaymentDate") ? dayjs(getValues("expectedPaymentDate")).toDate() : new Date()}
        display="inline"
        onCancel={() => setDateModalInfo((prevState) => ({ ...prevState, visible: false }))}
        accentColor={Colors.primary50}
        locale={i18n.language === LanguageStatus.VN ? "vi-VN" : "en-US"}
        confirmTextIOS={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          t("common:confirm")
        }
        cancelTextIOS={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          t("common:cancel")
        }
      />
    </View>
  );
};

export default CreateOrEditForm;
