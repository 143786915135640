import { AppText, BottomSheetModalCustomMethods, BottomSheetScrollViewModalCustom, Button, Line } from "components";
import { StyleSheet, View } from "react-native";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import { SwitchActiveIcon, SwitchIcon } from "assets/images/svg/icons";

const ExpenseCashAdvanceInfoModal = forwardRef((_, ref) => {
  useImperativeHandle(
    ref,
    () => ({
      present: () => {
        bottomSheetRef?.current?.present?.();
      },
    }),
    []
  );
  const { t } = useTranslation("app/screens/Report/components/CreateOrEditForm");
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>();
  return (
    <BottomSheetScrollViewModalCustom
      title={t("expense_has_advance")}
      ref={bottomSheetRef}
      containerStyle={styles.modalStyle}
      wrapperByScrollView={false}
      renderFooter={() => (
        <Button type="primary" onPress={() => bottomSheetRef.current?.close()}>
          {t("understood")}
        </Button>
      )}
    >
      <View style={styles.rowItem}>
        <View style={styles.switchButtonRow}>
          <AppText style={Fonts.Caption} color={Colors.grayscale60}>
            {t("on")}
          </AppText>
          <SwitchActiveIcon />
        </View>
        <AppText style={Fonts.BodyLarge}>{t("description_on_advance")}</AppText>
      </View>
      <Line />
      <View style={styles.rowItem}>
        <View style={styles.switchButtonRow}>
          <AppText style={Fonts.Caption} color={Colors.grayscale60}>
            {t("off")}
          </AppText>
          <SwitchIcon />
        </View>
        <AppText style={Fonts.BodyLarge}>{t("description_off_advance")}</AppText>
      </View>
    </BottomSheetScrollViewModalCustom>
  );
});

export default ExpenseCashAdvanceInfoModal;

const styles = StyleSheet.create({
  modalStyle: { paddingHorizontal: 20, paddingBottom: 100 },
  rowItem: { paddingVertical: 16 },
  switchButtonRow: { flexDirection: "row", gap: 4, alignItems: "center", marginBottom: 8 },
});
