import { AlertNotification, AlertNotificationHandle, BackHeader, useBackHandler, LoadingView } from "components";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "theme";
import CreateOrEditForm from "screens/Report/components/CreateOrEditForm/CreateOrEditForm";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { BizziBotWarning } from "assets/images/svg/icons";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { useAuth } from "contexts/AuthContext";
import { goBack } from "navigation/RootNavigation";
import { withTrackingSession } from "services/AnalyticsService";
import SCREEN_NAME from "navigation/ScreenName";
import { ReportFormValues } from "screens/Report/components/CreateOrEditForm/index.props";
import { ExpenseHelper } from "utils/expense";

const QUERY_EXPENSES_BY_IDS = gql`
  query MobileExpenses(
    $expenseIds: [UUID!]
    $employeeId: UUID!
    $claimedStatus: ExpExpenseClaimedStatus
    $requestIds: [UUID!]
  ) {
    expExpenses(
      where: {
        expenseIds: $expenseIds
        employeeId: $employeeId
        statuses: [3]
        claimedStatus: $claimedStatus
        requestIds: $requestIds
      }
    ) {
      expenses {
        docSequence
        expenseId
        expenseDate
        employeeId
        note
        status
        totalAmountWithVat
        totalAmountWithoutVat
        companyTeamId
        expenseCategory {
          expenseCategoryId
          title
          titleEn
        }
        currency
        exchangeRate
        foreignTotalAmountWithVat
        expenseRequestId
        expenseRequest {
          expenseRequestId
          docSequence
          title
          totalAmount
          cashAdvances {
            amount
          }
        }
        expenseCardTransactions {
          cardTransaction {
            amount
          }
        }
      }
    }
  }
`;

const ReportCreateScreen = ({ navigation, route }) => {
  const [isChangedFormInfo, setIsChangedFormInfo] = useState(false);
  const [formData, setFormData] = useState<ReportFormValues>(null);
  const { expenseIds, companyTeam, title, onRefreshData, requestIds, shouldShowWalkthrough } = route?.params ?? {};
  const { t } = useTranslation("app/screens/Report/ReportCreateScreen");
  const alertNotificationRef = useRef<AlertNotificationHandle>(null);
  const {
    user: { employee_id },
  } = useAuth();
  const {
    data: expensesData,
    refetch,
    loading: loadingExpenseList,
  } = useQuery(QUERY_EXPENSES_BY_IDS, {
    variables: { expenseIds, employeeId: employee_id, requestIds, claimedStatus: "UNCLAIMED" },
    skip: !expenseIds && !requestIds,
    notifyOnNetworkStatusChange: false,
  });
  /*-- on refresh data when update expense --> filter by companyTeam --*/
  const onRefreshExpense = async (expenseIds) => {
    const rs = await refetch?.({ expenseIds, employeeId: employee_id, claimedStatus: "UNCLAIMED" });
    setFormData((prevState) => ({ ...prevState, expenseList: rs?.data?.expExpenses?.expenses ?? [] }));
  };

  const showAlert = useCallback(() => {
    alertNotificationRef.current?.warning({
      icon: <BizziBotWarning />,
      title: t("cancel_title"),
      description: t("cancel_confirm"),
      cancelText: t("close"),
      confirmText: t("cancel"),
      onConfirm: () => {
        alertNotificationRef.current?.close();
        goBack();
      },
    });
  }, [navigation, t]);

  const handleGoBack = useCallback(() => {
    if (!isChangedFormInfo) {
      goBack();
      return;
    }
    showAlert();
  }, [isChangedFormInfo, navigation, showAlert]);

  useBackHandler(() => {
    if (isChangedFormInfo) {
      showAlert();
      return true;
    }
    return false;
  });

  useEffect(() => {
    if (!expensesData) {
      return;
    }

    const expenseList = expensesData?.expExpenses?.expenses ?? [];
    const formData: ReportFormValues = {
      title,
      companyTeam,
      expenseList,
    };
    setFormData(formData);
  }, [expensesData, title, companyTeam]);

  return (
    <BottomSheetModalProvider>
      <BackHeader headerTitle={t("create_expense_report")} onPress={handleGoBack} />
      {loadingExpenseList ? (
        <LoadingView />
      ) : (
        <View style={styles.container}>
          <CreateOrEditForm
            formData={formData}
            onRefreshData={onRefreshData}
            onRefreshExpense={onRefreshExpense}
            onChangeFormValues={(value) => setIsChangedFormInfo(value)}
            shouldShowWalkthrough={shouldShowWalkthrough}
          />
          <AlertNotification ref={alertNotificationRef} />
        </View>
      )}
    </BottomSheetModalProvider>
  );
};

export default withTrackingSession(ReportCreateScreen, SCREEN_NAME.NewReportScreen);

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.grayscale05 },
});
