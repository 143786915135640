import { StyleSheet } from "react-native";
import { Colors } from "theme";

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.grayscale05 },
  footerButtonLeft: { flex: 1, marginRight: 6 },
  totalAmountContainer: { marginBottom: 7 },
  footerButtonContainer: { flexDirection: "row", justifyContent: "space-between", gap: 8 },

  flex: { flex: 1 },
  descriptionItem: { flexDirection: "row", gap: 8 },
  dotIcon: { width: 5, height: 5, borderRadius: 3, backgroundColor: Colors.grayscale100, marginTop: 6 },
  errorContainer: { backgroundColor: Colors.white, paddingTop: 70, flex: 1 },
});

export default styles;
